import { setState, getState } from '../../shared/utils/state';
import WsInterface, {
    UNNAMED_WS_HANDLER_SCOPE as _UNNAMED_WS_HANDLER_SCOPE,
    MAIN_WS_HANDLER_NAME,
} from '../../legacy/modules/ws-interface/wsInterface';

export const UNNAMED_WS_HANDLER_SCOPE = _UNNAMED_WS_HANDLER_SCOPE;
export const WS_CONNECTION_NAME = MAIN_WS_HANDLER_NAME;
export const WEBSOCKETS_INTERFACE_STATE_KEY = 'websockets:interface';

export const WEBSOCKET_CLOSE_REASON_REG_TYPE_ID_CHANGE = 'Customer regulation_type_id changed';

export const createWsInterface = () => {
    const wsInterface = WsInterface(getState('websocket:settings'));

    return setState(WEBSOCKETS_INTERFACE_STATE_KEY, wsInterface);
};

export const getWsInterface = () => getState(WEBSOCKETS_INTERFACE_STATE_KEY);
